.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  background-color: var(--colorPrimaryDark); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--uniformeBlanco); 
}

.App-link {
  color: var(--colorAccent);
}



