.IM_imgRedondeada80 {
  position: relative;
  width: 100%;
  max-width: 80px;
  min-width: 80px;
  border-radius: 50%;         
  background-size: cover;
  display: block;
  background-position: center;
  margin: 4px;
}

.IM_imgRedondeada46 {
  position: relative;
  width: 100%;
  max-width: 46px;
  min-width: 46px;
  border-radius: 50%;         
  background-size: cover;
  display: block;
  background-position: center;
  margin: 4px;
}

.IM_imgRedondeada36 {
    position: relative;
    width: 100%;
    max-width: 36px;
    min-width: 36px;
    border-radius: 50%;         
    background-size: cover;
    display: block;
    background-position: center;
    margin: 0px;
  }
  
  .IM_imgRedondeada28 {
    position: relative;
    width: 100%;
    max-width: 28px;
    min-width: 28px;
    border-radius: 50%;         
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_imgRedondeada24 {
    position: relative;
    width: 100%;
    max-width: 24px;
    min-width: 24px;
    border-radius: 50%;         
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_imgRedondeada250 {
    position: relative;
    width: 100%;
    max-width: 500px;
    min-width: 250px;
    border-radius: 50%;         
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_imgIcono250 {
    position: relative;
    width: 100%;
    max-width: 500px;
    min-width: 250px;   
    max-height: 500px;
    min-height: 250px;      
    display: block;
    background-position: center;
  }

  .IM_imgIcono46 {
    position: relative;
    width: 100%;
    max-width: 46px;
    min-width: 46px;   
    max-height: 46px;
    min-height: 46px;      
    display: block;
    background-position: center;
  }

  .IM_imgIcono36 {
    position: relative;
    width: 100%;
    max-width: 36px;
    min-width: 36px;   
    max-height: 36px;
    min-height: 36px;      
    display: block;
    background-position: center;
  }

  .IM_imgIcono28 {
    position: relative;
    width: 100%;
    max-width: 28px;
    min-width: 28px;     
    max-height: 28px;
    min-height: 28px;  
    background-size: cover;
    display: block;
    background-position: center;
  }
  
  .IM_imgIcono24 {
    position: relative;
    width: 100%;
    max-width: 24px;
    min-width: 24px;     
    max-height: 24px;
    min-height: 24px;  
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_imgIcono20 {
    position: relative;
    width: 100%;
    max-width: 20px;
    min-width: 20px;     
    max-height: 20px;
    min-height: 20px;  
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_imgIcono16 {
    position: relative;
    width: 100%;
    max-width: 16px;
    min-width: 16px;     
    max-height: 16px;
    min-height: 16px;  
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_camiseta65 {
    position: relative;
    width: 100%;
    max-width: 54px;
    min-width: 54px;     
    max-height: 65px;
    min-height: 65px;  
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_camiseta55 {
    position: relative;
    width: 100%;
    max-width: 44px;
    min-width: 44px;     
    max-height: 55px;
    min-height: 55px;  
    background-size: cover;
    display: block;
    background-position: center;
  }

  .IM_camiseta {
    position: relative;
    width: 100%;
    max-width: 32px;
    min-width: 32px;     
    max-height: 40px;
    min-height: 40px;  
    background-size: cover;
    display: block;
    background-position: center;
  }
  
  .IM_imgPublicidad {
    position: relative;
    width: 100%;
    max-width: 600px;
    min-width: 300px;       
    background-size: cover;
    display: block;
    background-position: center;
    cursor: pointer;
  }
  
.IM_imagenConTexto{
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align:middle;
}

.IM_textoCentardoImagen{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.IM_numeroCamiseta{
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 12px;
  color: var(--uniformeBlanco);
}


.IM_botonRedondeado {
  width: 100%;
  max-width: 28px;
  min-width: 28px;
  border-radius: 10%;         
  background-size: cover;
  display: block;
  background-position: center;
  margin: 8px;
  background-color: var(--colorAccent);
  cursor: pointer;
}
