.MD_Modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: var(--black_overlay);
    display: none;
    justify-content: center;
    align-items: center;
  }

  .MD_Modal_Alerta {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    color: var(--uniformeBlanco);
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 2s .5s;
  }

  .MD_Modal_Alerta.isOpen {
    display: flex; 
  }
  
  .MD_Contenedor {
    position: relative;
    padding: 0px;
    min-width: 250px;
    max-width: 600px;
    min-height: 200px;
    max-height: 90vh;    
    overflow: auto;
    background-color: var(--uniformeBlanco);
    border-radius: 5px;
    
  }

  .MD_ContenedorFull {
    position: relative;
    padding: 0px;
    min-width: 90vw;
    max-width: 90vw;
    min-height: 85vh;
    max-height: 85vh;
    background-color: var(--uniformeBlanco);
    border-radius: 5px;    
  }
  
  .MD_Cerrar {
    position: absolute;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
    padding: 8px;
  }

  .MD_Botones {
    position: absolute;
    bottom: 0rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    align-items: center;
    width: 93%;
    min-width: 150px;
    max-width: 90ww;
  }
  
  .MD_Modal.isOpen {
    display: flex;
  }



  .MD_MenuFlotante {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: var(--fondoPrimaryDarkSuave);
    display: none;
  }

  .MD_MenuFlotante.isOpen {
    display: flex;
  }

  .MD_ContenedorFlotante {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0px;
    min-width: 100px;
    max-width: 300px;
    min-height: 100px;
    max-height: 90vh;
    overflow: auto;
    background-color: var(--uniformeBlanco);
    border-radius: 5px;    
    border: 1px solid var(--fondoPaginas);
  }

  .MD_ItemMenuFlotante {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;
    padding-block: 4px;
    padding-left: 4px;
    padding-right: 14px;
    cursor: pointer;
  }

  .MD_ItemMenuFlotante:hover {
    background-color: var(--fondoAccentSuave);
  }


  .MD_MenuLateral {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: var(--black_overlay);
    display: none;
    transition: all 300ms;
  }

  .MD_MenuLateral.isOpen {   
    display: flex; 
  }

  .MD_ContenedorLateral {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px;
    min-width: 100px;
    max-width: 75vw;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    background: var(--uniformeBlanco);   
    transition: all 300ms; 
  }

