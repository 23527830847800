
.TX_unaSolaLinea {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
  
  .TX_unaSolaLinea2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    text-decoration: none;
  }

  
  .TX_textoPeq {
    font-size: 12px;    
  }

  .TX_letraPeq{
    font-size: 12px;
    color: var(--uniformeGrisOscuro);
  }

  .TX_letraMuyPeq{
    font-size:10px;
    color: var(--uniformeGrisOscuro);
  }

  .TX_tituloCaja{
    font-size: 14px;
    color: var(--uniformeNegro);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    text-align: left;
  }

  .TX_sinInfo {
    font-size: 16px; 
    color: var(--colorTextoSinInfo);
    text-align: center;   
    padding: 4px;
    margin: 4px;
  }

  .TX_error {
    font-size: 16px; 
    color: var(--colorTextoError);
    text-align: center;   
    padding: 4px;
    margin: 4px;
  }

  .TX_exito {
    font-size: 16px; 
    color: var(--colorTextoExito);
    text-align: center;   
    padding: 4px;
    margin: 4px;
  }

  .TX_neutro {
    font-size: 16px; 
    color: var(--uniformeGrisOscuro);
    text-align: center;   
    padding: 4px;
    margin: 4px;
  }


  .TX_link {
    font-size: 16px; 
    color: var(--colorTextoSinInfo);
    text-align: center; 
    cursor: pointer;    
    text-decoration: none;
  }