.contenedorLoader {
  position: fixed;
	top: 0;
	left: 0;
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: var(--fondoPaginas);
	border-style: none;  
	opacity: 0.2;
	filter:alpha(opacity=20);
  z-index: 9999;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    padding-top: 0px;
  }  

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid var(--colorAccent);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--colorAccent) transparent transparent transparent;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid var(--colorAccent);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--colorAccent) transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--uniformeNegro);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: 1s all;  
  opacity: 0.5;
}

.spin {
  border: 4px solid var(--colorAccent);
  border-top-color: var(--uniformeNegro);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.spin.a {
  border: 2px solid var(--uniformeGrisClaro);
  border-top-color: var(--colorPrimary);
  border-radius: 100%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
to {
  transform: rotate(360deg);
}
}



