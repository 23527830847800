.BF_botonBordeAccentFull {
    border: 2px solid var(--colorAccent);
    background: var(--colorAccent);
    border-radius: 5px;
    padding: 4px;
    color: var(--uniformeNegro);
    text-decoration: none;
    cursor: pointer;
    display: block;
    font-size: 16px;
    max-width: 300px;
    min-width: 80px;
    margin-bottom: 10px;
  }
  
  .BF_botonBordeAccent {
      border: 3px solid var(--colorAccent);
      border-radius: 5px;
      padding: 4px;
      color: var(--colorAccent);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }

    .BF_botonBordePrimary {
      border: 3px solid var(--colorPrimary);
      border-radius: 5px;
      padding: 4px;
      color: var(--colorPrimary);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }

    .BF_botonBordeGris {
      border: 3px solid var(--fondoPaginas);
      border-radius: 5px;
      padding: 4px;
      color: var(--uniformeGrisOscuro);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 120px;
      min-width: 120px;
      max-height: 30px;
      margin-bottom: 10px;
    }

    .BF_botonBordeRojo {
      border: 3px solid var(--uniformeRojo);
      border-radius: 5px;
      padding: 4px;
      color: var(--uniformeRojo);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }
  
    .BF_botonPrimaryFull {
      border: 2px solid var(--colorPrimary);
      background: var(--colorPrimary);
      border-radius: 5px;
      padding: 4px;
      color: var(--uniformeBlanco);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }

    .BF_botonPrimaryFull:hover{
      opacity: 0.8;
    }

    .BF_botonSinColor {
      border-radius: 5px;
      padding: 4px;
      color: var(--uniformeNegro);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }

    .BF_botonTransparenteFull {
      border: 2px solid transparent;
      background: transparent;
      border-radius: 5px;
      padding: 4px;
      color: var(--colorPrimary);
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }

    .BF_botonTransparenteFull:hover{
      opacity: 0.8;
    }


  
    .BF_botonPrimary {
      border: 3px solid var(--colorPrimary);
      border-radius: 5px;
      padding: 4px;
      color: var(--colorPrimary);
      text-decoration: none;
      cursor: pointer;
      display: block;
      font-size: 16px;
      max-width: 300px;
      min-width: 80px;
      margin-bottom: 10px;
    }
  
  
    
  
    
  .BF_bordeTotal {
      border: 1px solid var(--uniformeGrisClaro); 
      border-radius: 7px 7px 7px 7px;
      -moz-border-radius: 7px 7px 7px 7px;
      -webkit-border-radius: 7px 7px 7px 7px;
    }
  
    .BF_bordeTotal3 {
      border: 3px solid var(--uniformeGrisClaro); 
      border-radius: 7px 7px 7px 7px;
      -moz-border-radius: 7px 7px 7px 7px;
      -webkit-border-radius: 7px 7px 7px 7px;
    }
    
    .BF_lineaInferior {
      border-bottom: 1px solid var(--uniformeGrisClaro);
      height: 1px;
      width: 97%;
      padding: 0px;
      margin: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .BF_lineaDerecha {
      border-right: 1px solid var(--uniformeGrisClaro);
      height: 50%;
      padding: 0px;
      margin: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  
    .BF_cajaAccent {
      border: 2px solid var(--colorAccent); 
      background: var(--colorAccent);
      border-radius: 5px;
    }
  
    .BF_cajaGris {
      border: 2px solid var(--fondoPaginas); 
      background: var(--fondoPaginas);
      border-radius: 5px;
    }

    .BF_cajaGrisClara {      
      background: var(--uniformeGrisSuperClaro);
      border-radius: 5px;
    }
  
    
    .BF_cajaBlanca { 
      border: 2px solid var(--uniformeBlanco); 
      background: var(--uniformeBlanco);
      border-radius: 5px;
    }

    .BF_fondolistaspar {
      background: var(--fondoAccentSuave);
      border-radius: 4px;
    }
  
  
    .BF_bordePrimary {
      border: 2px solid var(--colorPrimary); 
      border-radius: 7px 7px 7px 7px;
      -moz-border-radius: 7px 7px 7px 7px;
      -webkit-border-radius: 7px 7px 7px 7px;
      padding: 2px;
    }
  
  
  
    .ratio { 
      height: 100px; 
      width: 100px; 
      border-radius: 50%; 
      position: relative; 
      clip-path: circle(45%); 
    } 
    
    .ratio::before 
    { 
      content: ''; 
      position: absolute; 
      width: 100%; 
      height: 100%; 
      top: 0; 
      left: 0; 
      background-image: conic-gradient(var(--colorAccent) 0 calc(var(--ratio) * 360deg), var(--uniformeGrisClaro)  calc(var(--ratio) * 360deg) 360deg); 
      z-index: -2; 
    } 
  
    .ratio::after 
    { 
      content: ''; 
      position: 
      absolute; 
      width: 80%; 
      height: 80%; 
      top: 10%; 
      left: 10%; 
      background-color: var(--uniformeBlanco); 
      border-radius: 50%; 
      z-index: -1; 
    }
  
    .BF_fondoArco {
      border: 3px solid var(--uniformeGrisClaro); 
      border-bottom: 0px;
      background: var(--fondolistas);
      
    }

  .progressVacio {
      height: 8px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid var(--fondoPaginas);
      background-color: var(--fondolistas);
      display:flex;
      justify-content: left;
  }

  .progress {
      height: 8px;
      width: 100%;
      border-radius: 5px;
      background-color: var(--colorAccent);
      display:flex;
      justify-content: left;
  }
   
  .progress-bar {
      height: 100%;
      background: var(--colorPrimary);
      display: flex;
      align-items: center;
      transition: width 0.25s;
      border-radius: 5px;
  }


  .BF_Fix_BordeGanador {
    border: 2px solid var(--colorPrimary);
    border-radius: 4px;
    text-decoration: none;
  }

  .BF_Fix_BordePerdedor {
    border: 2px solid var(--fondoPaginas);
    border-radius: 4px;
    text-decoration: none;
  }

  .BF_Fix_Fondo_Accent {
    border: 2px solid var(--colorAccent);
    background: var(--colorAccent);
    border-radius: 4px;
    text-decoration: none;
  }

  .BF_Fix_Borde_Gris {
    border: 2px solid var(--fondoPaginas);
    border-radius: 4px;
    color: var(--uniformeGrisOscuro);
    text-decoration: none;
  }

  .BF_Fix_Borde_Accent {
    border: 2px solid var(--colorAccent);
    border-radius: 4px;
    color: var(--uniformeNegro);
    text-decoration: none;
  }

  .BF_Fix_Borde_Transparent {
    border: 2px solid var(--transparente);
    border-radius: 4px;
    color: var(--uniformeNegro);
    text-decoration: none;
  }

  .BF_Fix_Linea_Superior {
    border-top: 2px solid var(--fondoPaginas);
  }

  .BF_Fix_Linea_Inferior {
    border-bottom: 2px solid var(--fondoPaginas);
  }

  .BF_Fix_Esquina_SupDer {
    border-top: 2px solid var(--fondoPaginas);
    border-right: 2px solid var(--fondoPaginas);
    border-radius: 1px;
    margin-right: 0px;
  }

  .BF_Fix_Esquina_InfDer {
    border-bottom: 2px solid var(--fondoPaginas);
    border-right: 2px solid var(--fondoPaginas);
    border-radius: 1px;
    margin-right: 0px;
  }


  .BF_circuloGris {
    border-radius: 20px;
    background-color: var(--fondoPaginas);
    color: var(--uniformeNegro);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    width: 40px;
    height: 40px;
  }

  .BF_circuloGris.selected {
    background-color: var(--colorPrimary);
    color: var(--uniformeBlanco);
  }

  .BF_botonAMPM {
    border-radius: 5px;
    padding: 4px;
    background-color: var(--fondoAccentSuave);
    cursor: pointer;
    display: block;
    font-size: 14px;
    color:var(--uniformeNegro);
    font-weight: bold;
    width: 100%;
  }

  .BF_botonAMPM.selected {
    background-color: var(--colorPrimary);
    color: var(--uniformeBlanco);
  }

  .BF_fondoClaro {
    background-color: var(--fondoAccentSuave);
  }




  
  