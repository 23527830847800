.CT_fondoVentana {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--uniformeGrisClaro);
  
}

.CT_fondoVentana2 {
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  overflow:hidden;

  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color:var(--uniformeGrisClaro);
}

.CT_contenedorFondo { 
  display: flex;
  flex-wrap: nowrap;
  justify-content:center;
  overflow:hidden;
  min-height: 100vh; 
}

.CT_imagenFondo {
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  overflow:hidden;

  background-image: url(../recGraficos/fondo_main.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color:var(--colorPrimaryDark);
}

.CT_imagenFondoMenu {
  background-size: cover;
  background-image: url(../recGraficos/fondo_menu.jpg);  
  background-repeat: no-repeat;
}

.CT_colorFondo {
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  overflow:hidden;
  background-attachment: fixed;
  background-size: cover;
  background-color:var(--colorPrimaryDark);
  justify-content: center;
  align-items: center;
  display: flex;
}


.CT_contenedorPublicidad {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content:center;
    overflow: auto;
  }
  
  .CT_flexPublicidad {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    margin: 1.5em;
    max-width: 400px;
  }
  
  
  .CT_contenedorFlex {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }

 .CT_contSelects {
  max-width: 400px;
 }

  
  .CT_tablaCGrupo {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 250px;
    margin: 2em;
    max-width: 400px;
  }
  
  
  
  @media screen and (max-width: 500px) {
  
    .CT_contenedorPublicidad {
      width: 100%;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content:left;
      overflow: auto;
    }
  
    .CT_tablaCGrupo {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      margin-top: 0px;
    }  
  }
  
  
  
  
  
  
  
  
  .CT_encabezado {
    background:var(--colorPrimary) ;
    min-height: 54px;
    max-height: 54px;
    display: flex;
    flex-wrap: nowrap;
    justify-content:left;
    align-items: center;
    font-size:medium;
    font-weight: bold;
    color: var(--uniformeBlanco);
  }
  
  .CT_elementoEncabezado {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 4px;
  }
  
  
  .CT_zonaFija {
    width: 100%;
    position: fixed;
    z-index: 100;
  }

  .CT_zonaFijaInferior {
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    background: var(--colorPrimaryDark);
    color: var(--uniformeBlanco);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  
  .CT_menu {
    text-decoration: none;
    display: block;
    color: var(--uniformeBlanco);  
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .CT_menu:hover {
    background: var(--uniformeBlanco);
    color: var(--colorPrimaryDark);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 10PX;
    padding-right: 10PX;
    font-weight: bold;
    cursor: pointer;
  }
  
  .CT_menuSelected {
    background: var(--colorAccent);
    color: var(--uniformeNegro);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 10PX;
    padding-right: 10PX;
    font-weight: bold;
    text-decoration: none;
    display: block;
    margin-left: 3px;
    margin-right: 3px;
  }
  
  .CT_menuDeslizable {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content:left;
    overflow: auto;
    background: var(--colorPrimaryDark);
    color: var(--uniformeBlanco);
    align-items:  center;
    line-height: 35px;
  }

  .CT_menuCopas {
     margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content:left;
    overflow: visible;
    background: var(--uniformeBlanco);
    align-items:  center;
    line-height: 30px;
  }

  .CT_menuFixture {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content:left;
    overflow: auto;
    background: var(--uniformeBlanco);
    align-items:  center;
  }
  
  .CT_elementoDeslizable {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    white-space: pre;
    margin-top: 15px;
  }

  .CT_menuIndicadores {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content:left;
    overflow:auto;
    color: var(--uniformeGrisOscuro);
    align-items:  center;    
  }
  
  .CT_indicador {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;  
    white-space: pre;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content:center;    
  }
  
  
  
.CT_contenedorPartido {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  min-width: 200px;
  align-items: center;
}

.CT_elementoPartido {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 150px;
}

.CT_elementoPartido2 {
  flex-grow: 0;
  flex-shrink: 0; 
  display: block; 
}


.CT_contenedorTitulo {  
  display: flex;
  flex-wrap: nowrap;
  min-width: 200px;
  align-items: center;
  justify-items:left;  
}


.CT_regSancion {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 250px;
  max-width: 900px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-top: 0px;
}  

.CT_ventanaMensajes {
  max-height: calc(100vh - 150px);
}

.CT_listaMensajes {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 300px;
  max-width: 600px; 
  max-height: 100%;
}

.CT_cajaleermensaje {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 300px;
  max-width: 100%; 
  max-height: 100%;
  border: 1px solid var(--fondoPaginas); 
  border-radius: 5px;
}

.CT_cajaleermensaje2 {
  flex: 1;
}

.CT_mensajeModal {
  border: 1px solid var(--fondoPaginas); 
  border-radius: 5px;
}



@media screen and (max-width: 700px) {

  .CT_listaMensajes {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 300px;
    max-width: 700px; 
    max-height: 100%;
  }

  .CT_cajaleermensaje {
    display: none;
  }
}

@media not screen and (max-width: 700px) {

  .CT_cajaleermensaje2 {
    display: none;
  }
}


.CT_mensajes {
  display: flex;
  align-items: center;
  padding: 2px;
  margin-block: 4px;
  cursor: pointer;
  background: var(--transparente);
}

.CT_mensajes:hover {
  background: var(--fondoAccentSuave);
}

