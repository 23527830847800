body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior: contain;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --ratio: 0.5; 

  --transparente: #ffffff00;

  --colorPrimary: #689F38;
  --colorPrimaryDark: #3A3A3A;
  --colorAccent: #D4E644;

  --fondoAccentSuave: #d2e5432e;
  --fondoPrimarySuave: #689f382e;
  --fondoPrimaryDarkSuave: #3a3a3a2e;

  --colorMiCancha: #38943B;
  --colorMiCancha2: #419f55;
  --tarjetaAmarilla: #ffd000;
  --tarjetaRoja: #ff0004;
  --tarjetaAzul: #0059a9;
  --colorFondoModal: #666566;
  --colorFondoSelecionado: #D4E644;
  --colorTextoExito: #689F38;
  --colorSinDatos: #F81F1F;
  --colorTextoError: #FF0000;
  --colorTextoSinInfo: #007BFF;
  --colorModoSonido: #ffffff40;
  --colorEscudo1: #B2D5F1;
  --colorEscudo2: #FAAD76;

  --black_overlay: #00000099;
  --background: #ececec;

  --uniformeRojo: #ff0000;
  --uniformeRosa: #FDB4B4;
  --uniformeAzulOscuro: #000089;
  --uniformeAzulClaro: #64B3F3;
  --uniformeVerdeOscuro: #014901;
  --uniformeVerdeClaro: #48B648;
  --uniformeNaranja: #ff6a00;
  --uniformAmarillo: #ffff00;
  --uniformeNegro: #000000;
  --uniformeBlanco: #ffffff;
  --uniformeGrisOscuro: #5f5f5f;
  --uniformeGrisClaro: #c5c5c5;
  --uniformeGrisSuperClaro: #e2e2e27a;
  --uniformeGrisMedio: #C5C5C5;
  --uniformeMorado: #a552ae;

  --fondoPaginas: #dedede;
  --fondoPaginasOscuro: #B9B9B9;
  --fondoPaginasContraste: #ffffff;
  --fondoPaginasTexto: #000000;
  --fondoPaginasContrasteTextoPrincipal: #000000;
  --fondoPaginasContrasteTextoSecundario: #c1c1c1;
  --fondolistaspar2: #d4e64466;
  --fondoProcesando: #3a3a3a96;
  --fondoInactivo: #dcdcdc73;

  --fondolistas: #f4f4f4;

  --fondolistaspar: #d4e64433;
  --fondolistasparrecuadro: #F6F8DF;
  --fondorecuadrorojo: #FDB4B4;
  --fondorechazado: #ffffffba;

  --fondoEncabezadoTabs: #3A3A3A;  

  --ocupado: #ff01013d;
  --otrajornada: #FDEDED;
  --nodisponible: #FF4848C8;
  --disponible: #15FF002D;
  --horaCancha: #F3F3F379;
  }
