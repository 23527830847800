
.IP_fechas {
    background: var(--uniformeBlanco);
    color: var(--uniformeGrisOscuro);
    border: 2px solid var(--colorAccent);
    border-radius: 3px 3px 3px 3px;
    height: 20px;
    width: 100px;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--uniformeBlanco);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #d1d3d1;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--colorAccent);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--uniformeBlanco);
}

.IP_entradas {
    outline: none !important;
    border:1px solid var(--uniformeGrisClaro);
    max-width: 600px;
    height: 25px;
    border-radius: 10px;
    color: var(--uniformeGrisOscuro);
    font-size: 16px;
}

.IP_entradas:focus {
    outline: none !important;
    border:2px solid var(--colorAccent);
    box-shadow: 0 0 5px var(--uniformeGrisClaro);    
}


.IP_entradasTA {
  outline: none !important;
  border:1px solid var(--uniformeGrisClaro);
  max-width: 600px;
  border-radius: 10px;
  color: var(--uniformeGrisOscuro);
  font-size: 16px;
}

.IP_entradasTA:focus {
  outline: none !important;
  border:2px solid var(--colorAccent);
  box-shadow: 0 0 5px var(--uniformeGrisClaro);    
}



.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--uniformeGrisClaro);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: var(--uniformeBlanco);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--colorAccent);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--colorAccent);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }




  .slick-arrow.slick-prev {
    left: -7px;
    z-index: 1;
    height: 100%;  
    width: 30px; 
    padding-top: 40px;  
  }
  .slick-arrow.slick-next {
    right: -7px;
    z-index: 1;
    height: 100%; 
    width: 30px; 
    padding-top: 40px;  
  }

  .slick-prev:before,
.slick-next:before {
  color: var(--uniformeGrisOscuro)!important;
}
 

.starsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.star {
  cursor: pointer;
  transition: all 0.1s linear;
  margin: 0;
  padding: 0;
}

.star.habilitado:hover {
  transform: scale(1.2);
}